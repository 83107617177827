import React from "react";
import "./Hotels.css";
import { Link, useLocation } from "react-router-dom";
import HotelSwiper from "../Swiper/Swiper";
import stars from "../../images/stars.svg";
import pin from "../../images/pin.svg";
import food from "../../images/food.svg";
import spa from "../../images/spa.svg";
import fitness from "../../images/fitness.svg";
import casino from "../../images/casino.svg";
import pool from "../../images/pool.svg";
import business from "../../images/business.svg";
import design from "../../images/design.svg";
import bar from "../../images/bar.svg";
import center from "../../images/center.svg";

import hotOneOne from "../../images/JW-Marriott-Bucharest-Grand-Hotel-1.webp";
import hotOneTwo from "../../images/JW-Marriott-Bucharest-Grand-Hotel-2.webp";
import hotOneThree from "../../images/JW-Marriott-Bucharest-Grand-Hotel-3.webp";
import hotTwoOne from "../../images/Radisson-BLU-Bucharest-1.webp";
import hotTwoTwo from "../../images/Radisson-BLU-Bucharest-2.webp";
import hotTwoThree from "../../images/Radisson-BLU-Bucharest-3.webp";
import hotThreeOne from "../../images/Sheraton-Bucharest-Hotel-1.webp";
import hotThreeTwo from "../../images/Sheraton-Bucharest-Hotel-2.webp";
import hotThreeThree from "../../images/Sheraton-Bucharest-Hotel-3.webp";
import hotFourOne from "../../images/Qosmo-Brasov-Hotel-1.webp";
import hotFourTwo from "../../images/Qosmo-Brasov-Hotel-2.webp";
import hotFourThree from "../../images/Qosmo-Brasov-Hotel-3.webp";

const Hotels = () => {
  const location = useLocation();
  return (
    <section className="best-hotels mg-top">
      {location.pathname === "/best-hotels" ? (
        <h1 className="best-hotels__header">Cele mai bune hoteluri</h1>
      ) : (
        <h2 className="best-hotels__header">Cele mai bune hoteluri</h2>
      )}
      <ul className="best-hotels__list">
        <li className="best-hotels__item bg-brown">
          <div className="best-hotels__img img-1"></div>
          <div className="best-hotels__info">
            <h2 className="col-white">JW Marriott Bucharest Grand Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16 text-center">Minunat</p>
            <ul className="best-hotels__perks">
              <li className="best-hotels__perk">
                <img src={pin} alt="pin" title="pin" />
                Calea 13 Septembrie 90, Bucharest, 050726
              </li>
              <li className="best-hotels__perk">
                <img src={food} alt="food" title="food" />
                Restaurante de elită
              </li>
              <li className="best-hotels__perk">
                <img src={spa} alt="spa" title="spa" />
                Complex spa
              </li>
              <li className="best-hotels__perk">
                <img src={fitness} alt="fitness" title="fitness" />
                Centru de fitness
              </li>
              <li className="best-hotels__perk">
                <img src={casino} alt="casino" title="casino" />
                Cazinou
              </li>
            </ul>
            <HotelSwiper
              imgOne={hotOneOne}
              imgTwo={hotOneTwo}
              imgThree={hotOneThree}
              name={"JW Marriott Bucharest Grand Hotel"}
            />
            <p className="t-16 text-center best-hotels__pad">
              Promoții:
              <br></br>reduceri la cazare atunci când rezervarea se face în
              avans, pachete all-inclusive
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea hotelului:
              <br></br>Acest hotel este o combinație de lux și confort modern.
              JW Marriott Bucharest Grand Hotel este cunoscut pentru serviciile
              sale de lux și facilitățile de primă clasă. Oaspeții se pot bucura
              de camere spațioase, interioare elegante și o varietate de
              restaurante care servesc preparate din întreaga lume. Un centru
              spa și o sală de fitness sunt disponibile pentru relaxare.
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea cazinoului:
              <br></br>Cazinoul de la JW Marriott este locul unde eleganța
              întâlnește emoția. Aici oaspeții își pot încerca norocul la
              ruletă, blackjack sau poker într-un cadru luxos.
            </p>
            <Link
              to="https://www.expedia.com/Bucharest-Hotels-JW-Marriott-Bucharest-Grand-Hotel.h570476.Hotel-Information?"
              className="best-hotels__link t-16"
            >
              Rezervă
            </Link>
          </div>
        </li>

        <li className="best-hotels__item bg-light">
          <div className="best-hotels__info">
            <h2 className="col-white">Radisson BLU Bucharest</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16 text-center">Excelentă</p>
            <ul className="best-hotels__perks">
              <li className="best-hotels__perk">
                <img src={pin} alt="pin" title="pin" />
                Calea Victoriei 63-81, Sector1, Bucharest, 010065
              </li>
              <li className="best-hotels__perk">
                <img src={pool} alt="pool" title="pool" />
                Piscină interioară
              </li>
              <li className="best-hotels__perk">
                <img src={spa} alt="spa" title="spa" />
                Complex spa
              </li>
              <li className="best-hotels__perk">
                <img src={business} alt="business" title="business" />
                Servicii de afaceri
              </li>
              <li className="best-hotels__perk">
                <img src={casino} alt="casino" title="casino" />
                Cazinou premium
              </li>
            </ul>
            <HotelSwiper
              imgOne={hotTwoOne}
              imgTwo={hotTwoTwo}
              imgThree={hotTwoThree}
              name={"Radisson BLU Bucharest"}
            />
            <p className="t-16 text-center best-hotels__pad">
              Promoții:
              <br></br>tarife speciale pentru membrii fideli, reduceri pentru
              grupuri
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea hotelului:
              <br></br>Radisson BLU este unul dintre cele mai bune hoteluri din
              centrul Bucureștiului, unde stilul întâlnește confortul. Ideal
              atât pentru călătorii de afaceri, cât și pentru cei de plăcere.
              Hotelul oferă piscine interioare și în aer liber, un centru de
              wellness și restaurante gourmet.
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea cazinoului:
              <br></br>Cazinoul oferă o varietate de jocuri, de la sloturi la
              jocuri de cărți. Împrejurimile rafinate și standardele ridicate de
              servicii fac din acest cazinou o alegere excelentă pentru
              iubitorii de vacanțe de elită.
            </p>
            <Link
              to="https://www.expedia.com/Bucharest-Hotels-Radisson-BLU-Bucharest.h1879799.Hotel-Information?"
              className="best-hotels__link t-16"
            >
              Rezervă
            </Link>
          </div>
          <div className="best-hotels__img img-2"></div>
        </li>

        <li className="best-hotels__item bg-brown">
          <div className="best-hotels__img img-3"></div>
          <div className="best-hotels__info">
            <h2 className="col-white">Sheraton Bucharest Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16 text-center">Excelentă</p>
            <ul className="best-hotels__perks">
              <li className="best-hotels__perk">
                <img src={pin} alt="pin" title="pin" />
                5-7 Calea Dorobantilor, Bucharest, 010551
              </li>
              <li className="best-hotels__perk">
                <img src={food} alt="food" title="food" />
                International restaurants
              </li>
              <li className="best-hotels__perk">
                <img src={business} alt="business" title="business" />
                Modern conference rooms
              </li>
              <li className="best-hotels__perk">
                <img src={fitness} alt="fitness" title="fitness" />
                Centru de fitness
              </li>
              <li className="best-hotels__perk">
                <img src={casino} alt="casino" title="casino" />
                Cazinou
              </li>
            </ul>
            <HotelSwiper
              imgOne={hotThreeOne}
              imgTwo={hotThreeTwo}
              imgThree={hotThreeThree}
              name={"Sheraton Bucharest Hotel"}
            />
            <p className="t-16 text-center best-hotels__pad">
              Promoții:
              <br></br>bonus programs for regular guests, seasonal discounts
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea hotelului:
              <br></br>Sheraton Bucharest Hotel offers stylish and comfortable
              rooms with panoramic city views. From a fitness center to gourmet
              restaurants, you'll find everything you need for a relaxing stay.
              The hotel offers first-class facilities for both leisure and
              business meetings.
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea cazinoului:
              <br></br>The Sheraton Casino is characterized by its coziness and
              attention to detail. Here guests can enjoy gambling in a relaxed
              yet luxurious environment with excellent service.
            </p>
            <Link
              to="https://www.expedia.com/Bucharest-Hotels-Sheraton-Bucharest-Hotel.h972600.Hotel-Information?"
              className="best-hotels__link t-16"
            >
              Rezervă
            </Link>
          </div>
        </li>

        <li className="best-hotels__item bg-light">
          <div className="best-hotels__info">
            <h2 className="col-white">Qosmo Brasov Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16 text-center">Excepțional</p>
            <ul className="best-hotels__perks">
              <li className="best-hotels__perk">
                <img src={pin} alt="pin" title="pin" />4 pp. Zaharia Stancu,
                Brașov, BV, 507190
              </li>
              <li className="best-hotels__perk">
                <img src={design} alt="design" title="design" />
                Design unic
              </li>
              <li className="best-hotels__perk">
                <img src={bar} alt="bar" title="bar" />
                Bar pe acoperiș
              </li>
              <li className="best-hotels__perk">
                <img src={center} alt="center" title="center" />
                Proximitatea față de centrul istoric
              </li>
              <li className="best-hotels__perk">
                <img src={casino} alt="casino" title="casino" />
                Cazinou
              </li>
            </ul>
            <HotelSwiper
              imgOne={hotFourOne}
              imgTwo={hotFourTwo}
              imgThree={hotFourThree}
              name={"Qosmo Brasov Hotel"}
            />
            <p className="t-16 text-center best-hotels__pad">
              Promoții:
              <br></br>mic dejun și transferuri gratuite la rezervarea directă,
              reduceri pentru sejururi mai lungi grupuri
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea hotelului:
              <br></br>Hotelul Qosmo Brașov este un hotel elegant și modern
              situat în inima Brașovului. Aici oaspeții se pot bucura de
              facilități de înaltă clasă și vederi uimitoare la munte. Hotelul
              este ideal pentru cei care doresc să combine o vacanță în orașul
              istoric cu confortul serviciilor hoteliere moderne.
            </p>
            <p className="t-16 best-hotels__pad">
              Descrierea cazinoului:
              <br></br>Cazinoul de la Qosmo oferă oaspeților o varietate de
              jocuri într-un mediu relaxat. Slot machines, precum și jocuri de
              masă sunt disponibile în zona de jocuri, făcându-l o destinație
              populară pentru entuziaștii jocurilor de noroc.
            </p>
            <Link
              to="https://www.expedia.com/Brasov-Hotels-Qosmo-Brasov-Hotel.h66045573.Hotel-Information?"
              className="best-hotels__link t-16"
            >
              Rezervă
            </Link>
          </div>
          <div className="best-hotels__img img-4"></div>
        </li>
      </ul>
    </section>
  );
};

export default Hotels;
