import React from "react";
import "./About.css";
import { useLocation} from "react-router-dom";
import about1 from "../../images/about-1.webp";
import about2 from "../../images/about-2.webp";

const About = () => {
  const location = useLocation();

  return (
    <section className="best-about mg-top">
      <div className="best-about__container best-about__left">
        <img src={about1} alt="fântână unul" title="fântână unul" className="best-about__img"/>
        {location.pathname === "/about-us" ? (
          <h1 className="best-about__header">Despre noi</h1>
        ) : (
          <h2 className="best-about__header">Despre noi</h2>
        )}
        <p className="t-20 col-brown">
          Suntem o echipă de experți pasionați de lumea hotelurilor cazinou.
          Site-ul nostru este conceput pentru a vă ajuta să alegeți cele mai
          bune hoteluri cazinou din România. Cercetăm temeinic fiecare hotel
          pentru a vă oferi informații imparțiale și actualizate cu privire la
          confort, servicii și opțiuni de joc. Scopul nostru este să vă oferim o
          experiență de neuitat, combinând luxul și jocurile de noroc într-un
          singur loc.
        </p>
        <div className="best-about__text-container">
          <h2 className="col-white">Serviciile noastre</h2>
          <p className="best-about__text">
            Oferim recenzii detaliate ale hotelurilor cazinou, inclusiv
            informații despre evaluări, promoții și beneficii unice. Experții
            noștri vizitează fiecare unitate pentru a se asigura că înțelegeți
            pe deplin serviciile, jocurile și facilitățile de agrement oferite.
            Ne străduim să vă facem șederea la aceste hoteluri cât mai
            confortabilă și plăcută posibil.
          </p>
        </div>
      </div>
      <div className="best-about__container best-about__right">
        <div className="best-about__text-container">
          <h2 className="col-white">Misiunea noastră</h2>
          <p className="best-about__text">
            Misiunea noastră este să fim ghidul dumneavoastră de încredere în
            lumea hotelurilor cazinou. Vă ajutăm să le alegeți pe cele mai bune,
            concentrându-ne pe factori precum siguranța, calitatea serviciilor
            și experiența de joc. Suntem la curent cu cele mai recente evoluții
            din industria ospitalității și a jocurilor de noroc pentru a vă
            oferi cele mai actuale și de încredere recomandări.
          </p>
        </div>
        <h2 className="best-about__header">De ce să ne alegeții</h2>
        <ul className="best-about__list">
          <li className="best-about__item t-20">
            Fiabilitate - oferim recenzii oneste și independente.
          </li>
          <li className="best-about__item t-20">
            Expertiză - experiența noastră în industria ospitalității și a
            cazinourilor ne ajută să găsim și să evaluăm cele mai bune oferte.
          </li>
          <li className="best-about__item t-20">
            Actualizare - ne menținem informațiile la zi, astfel încât să aveți
            întotdeauna acces la cele mai recente informații.
          </li>
          <li className="best-about__item t-20">
            Asistență - suntem întotdeauna disponibili pentru a vă ajuta cu
            alegerile dvs. și pentru a răspunde la orice întrebări pe care le
            puteți avea.
          </li>
        </ul>
        <img src={about2} alt="fântână doi" title="fântână doi" className="best-about__img"/>
      </div>
    </section>
  );
};

export default About;
