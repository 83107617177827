import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Swiper.css";

const HotelSwiper = ({ imgOne, imgTwo, imgThree, name }) => {
  return (
    <Swiper
      modules={[Pagination]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="best-hotels__swiper mob"
    >
      <div className="best-hotels__rect rect-1"></div>
      <div className="best-hotels__rect rect-2"></div>
      <SwiperSlide className="hotel-slide">
        <img
          src={imgOne}
          alt={name}
          title={name}
          className="best-hotels__img-swiper"
        />
      </SwiperSlide>
      <SwiperSlide className="hotel-slide">
        <img
          src={imgTwo}
          alt={name}
          title={name}
          className="best-hotels__img-swiper"
        />
      </SwiperSlide>
      <SwiperSlide className="hotel-slide">
        <img
          src={imgThree}
          alt={name}
          title={name}
          className="best-hotels__img-swiper"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default HotelSwiper;
