import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="best-popup-cookie">
        <form className="best-popup__form">
          <p className="best-popup__text">
            Site-ul nostru utilizează module cookie pentru a ne îmbunătăți
            serviciile și pentru a vă personaliza experiența. Modulele cookie ne
            permit să vă reținem preferințele, să analizăm traficul și să
            asigurăm securitatea site-ului nostru web.
          </p>
          <div className="best-popup__btn-container">
            <a href="/cookies" className="best-popup__btn-cookie">
            Citește mai mult
            </a>
            <p
              className="best-popup__btn-cookie best-popup__btn-agree col-blue"
              onClick={addPopupCookie}
            >
              Acceptați
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
