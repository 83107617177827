import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Deals.css";
import stars from "../../images/stars.svg";
import hotOne from "../../images/Qosmo-Brasov-Hotel-1.webp";
import hotTwo from "../../images/Sheraton-Bucharest-Hotel-1.webp";
import hotThree from "../../images/Radisson-BLU-Bucharest-1.webp";

const Deals = () => {
  const location = useLocation();
  return (
    <section className="best-deals mg-top">
      {location.pathname === "/best-offers" ? (
        <h1 className="best-deals__header">Cele mai bune oferte</h1>
      ) : (
        <h2 className="best-deals__header">Cele mai bune oferte</h2>
      )}
      <div className="best-deals__body">
        <ul className="best-deals__list">
          <li className="best-deals__item">
            <h2 className="col-white">Qosmo Brasov Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16">Excepțional</p>
            <div className="best-deals__img-container">
              <div className="best-deals__rect deals-rect-1"></div>
              <div className="best-deals__rect deals-rect-2"></div>
              <img
                src={hotOne}
                alt="Qosmo Brasov Hotel hot deal"
                title="Qosmo Brasov Hotel hot-deal"
                className="best-hotels__img-swiper"
              />
            </div>
            <div className="best-deals__price">
              <p className="best-deals__old-price">560€</p>
              <p className="best-deals__new-price">460€</p>
            </div>
            <Link
              to="https://www.expedia.com/Brasov-Hotels-Qosmo-Brasov-Hotel.h66045573.Hotel-Information?"
              className="best-hotels__link best-deals__link t-16"
            >
              Rezervă
            </Link>
          </li>

          <li className="best-deals__item">
            <h2 className="col-white">Sheraton Bucharest Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16">Excelentă</p>
            <div className="best-deals__img-container">
              <div className="best-deals__rect deals-rect-1"></div>
              <div className="best-deals__rect deals-rect-2"></div>
              <img
                src={hotTwo}
                alt="Sheraton Bucharest Hotel hot deal"
                title="Sheraton Bucharest Hotel hot deal"
                className="best-hotels__img-swiper"
              />
            </div>
            <div className="best-deals__price">
              <p className="best-deals__old-price">540€</p>
              <p className="best-deals__new-price">430€</p>
            </div>
            <Link
              to="https://www.expedia.com/Bucharest-Hotels-Sheraton-Bucharest-Hotel.h972600.Hotel-Information?"
              className="best-hotels__link best-deals__link t-16"
            >
              Rezervă
            </Link>
          </li>

          <li className="best-deals__item">
            <h2 className="col-white">Radisson BLU Bucharest</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16">Excelentă</p>
            <div className="best-deals__img-container">
              <div className="best-deals__rect deals-rect-1"></div>
              <div className="best-deals__rect deals-rect-2"></div>
              <img
                src={hotThree}
                alt="Radisson BLU Bucharest hot deal"
                title="Radisson BLU Bucharest hot deal"
                className="best-hotels__img-swiper"
              />
            </div>
            <div className="best-deals__price">
              <p className="best-deals__old-price">520€</p>
              <p className="best-deals__new-price">410€</p>
            </div>
            <Link
              to="https://www.expedia.com/Bucharest-Hotels-Radisson-BLU-Bucharest.h1879799.Hotel-Information?"
              className="best-hotels__link best-deals__link t-16"
            >
              Rezervă
            </Link>
          </li>
        </ul>
        <Swiper
          modules={[Pagination]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          spaceBetween={5}
          className="best-deals__swiper mob"
        >
          <SwiperSlide className="deal-slide">
          <h2 className="col-white">Qosmo Brasov Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <p className="t-16">Excepțional</p>
            <div className="best-deals__img-container">
              <div className="best-deals__rect deals-rect-1"></div>
              <div className="best-deals__rect deals-rect-2"></div>
              <img
                src={hotOne}
                alt="Qosmo Brasov Hotel hot deal"
                title="Qosmo Brasov Hotel hot-deal"
                className="best-hotels__img-swiper"
              />
            </div>
            <div className="best-deals__price">
              <p className="best-deals__old-price">560€</p>
              <p className="best-deals__new-price">460€</p>
            </div>
            <Link
              to="https://www.expedia.com/Brasov-Hotels-Qosmo-Brasov-Hotel.h66045573.Hotel-Information?"
              className="best-hotels__link best-deals__link t-16"
            >
              Rezervă
            </Link>
          </SwiperSlide>

          <SwiperSlide className="deal-slide">
            <h2 className="col-white">Qosmo Brasov Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <div className="best-deals__img-container">
              <div className="best-hotels__rect rect-1"></div>
              <div className="best-hotels__rect rect-2"></div>
              <img
                src={hotOne}
                alt="Qosmo Brasov Hotel"
                title="Qosmo Brasov Hotel"
                className="best-hotels__img-swiper"
              />
            </div>
            <div className="best-deals__price">
              <p className="best-deals__old-price">560€</p>
              <p className="best-deals__new-price">460€</p>
            </div>
            <Link
              to="https://www.expedia.com/Brasov-Hotels-Qosmo-Brasov-Hotel.h66045573.Hotel-Information?"
              className="best-hotels__link t-16"
            >
              Rezervă
            </Link>
          </SwiperSlide>

          <SwiperSlide className="deal-slide">
            <h2 className="col-white">Qosmo Brasov Hotel</h2>
            <img
              src={stars}
              alt="stars"
              title="stars"
              width="140"
              height="28"
            />
            <div className="best-deals__img-container">
              <div className="best-hotels__rect rect-1"></div>
              <div className="best-hotels__rect rect-2"></div>
              <img
                src={hotOne}
                alt="Qosmo Brasov Hotel"
                title="Qosmo Brasov Hotel"
                className="best-hotels__img-swiper"
              />
            </div>
            <div className="best-deals__price">
              <p className="best-deals__old-price">560€</p>
              <p className="best-deals__new-price">460€</p>
            </div>
            <Link
              to="https://www.expedia.com/Brasov-Hotels-Qosmo-Brasov-Hotel.h66045573.Hotel-Information?"
              className="best-hotels__link t-16"
            >
              Rezervă
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Deals;
