import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="best-privacy">
        <h1 className="privacy__header">
          Politica de Cookie-uri pentru best-rooffers.com
        </h1>
        <p className="t-16">
          Aceasta este politica de cookie-uri pentru best-rooffers.com,
          accesibilă prin best-rooffers.com.
        </p>
        <h2 className="privacy__header">Ce sunt cookie-urile</h2>
        <p className="t-16">
          Așa cum este obișnuit pentru aproape toate site-urile profesionale,
          acest site folosește cookie-uri, care sunt fișiere mici descărcate pe
          computerul dvs., pentru a îmbunătăți experiența dvs. Această pagină
          descrie ce informații colectează, cum le folosim și de ce trebuie să
          stocăm aceste cookie-uri uneori. Vom explica, de asemenea, cum puteți
          împiedica stocarea acestor cookie-uri, însă acest lucru poate afecta
          sau „rupe” anumite funcționalități ale site-ului.
        </p>
        <h2 className="privacy__header">Cum folosim cookie-urile</h2>
        <p className="t-16">
          Utilizăm cookie-uri din diverse motive, enumerate mai jos. Din păcate,
          în majoritatea cazurilor nu există opțiuni standardizate în industrie
          pentru a dezactiva cookie-urile fără a dezactiva complet
          funcționalitatea și caracteristicile pe care acestea le adaugă acestui
          site. Este recomandat să lăsați toate cookie-urile activate, dacă nu
          sunteți sigur dacă aveți nevoie de ele sau nu, în cazul în care sunt
          utilizate pentru a furniza un serviciu pe care îl utilizați.
        </p>
        <h2 className="privacy__header">Dezactivarea cookie-urilor</h2>
        <p className="t-16">
          Puteți preveni setarea cookie-urilor ajustând setările browserului
          dvs. (consultați secțiunea de ajutor a browserului dvs. pentru mai
          multe detalii). Rețineți că dezactivarea cookie-urilor va afecta
          funcționalitatea acestui site și a multor alte site-uri pe care le
          vizitați. Dezactivarea cookie-urilor va dezactiva de obicei și anumite
          funcționalități și caracteristici ale acestui site. Prin urmare, este
          recomandat să nu dezactivați cookie-urile.
        </p>
        <h2 className="privacy__header">Cookie-uri pe care le setăm</h2>
        <p className="t-16">
          Cookie-uri utilizate pentru configurarea site-ului. Pentru a vă
          asigura că aveți o experiență plăcută pe acest site, oferim o
          funcționalitate pentru a seta preferințele dvs. privind modul în care
          funcționează acest site atunci când îl utilizați. Pentru a ne aminti
          preferințele dvs., trebuie să setăm cookie-uri astfel încât aceste
          informații să poată fi accesate de fiecare dată când interacționați cu
          o pagină care este afectată de preferințele dvs.
        </p>
        <h2 className="privacy__header">Cookie-uri terțe</h2>
        <p className="t-16">
          În unele cazuri speciale, folosim și cookie-uri furnizate de terți de
          încredere. Secțiunea următoare descrie ce cookie-uri terțe puteți
          întâlni prin intermediul acestui site.
        </p>
        <p className="t-16">
          Acest site folosește Google Analytics, una dintre cele mai răspândite
          și de încredere soluții de analiză de pe web, pentru a ne ajuta să
          înțelegem cum utilizați site-ul și cum putem îmbunătăți experiența
          dvs. Aceste cookie-uri pot urmări lucruri precum cât timp petreceți pe
          site și ce pagini vizitați, astfel încât să putem continua să producem
          conținut relevant și captivant.
        </p>
        <p className="t-16">
          Pentru mai multe informații despre cookie-urile Google Analytics,
          vizitați pagina oficială Google Analytics.
        </p>
        <h2 className="privacy__header">Mai multe informații</h2>
        <p className="t-16">
          Sperăm că aceste informații v-au clarificat lucrurile și, așa cum am
          menționat mai devreme, este mai sigur să lăsați cookie-urile activate
          dacă nu sunteți sigur dacă aveți nevoie de ele, în cazul în care
          acestea interacționează cu una dintre funcționalitățile pe care le
          utilizați pe site-ul nostru.
        </p>
      </section>
    </main>
  );
};

export default Cookies;
