import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import About from "../About/About";
import Hotels from "../Hotels/Hotels";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import Deals from "../Deals/Deals";

function App() {
  return (
    <div className="best-page">
      <ScrollToTop />
      <Header />
      <Popup />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/best-hotels" element={<Hotels />} />
        <Route path="/best-offers" element={<Deals />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
