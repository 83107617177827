import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const navigation = useNavigate();

  function handleClick() {
    setIsActive(false);
  }

  const handleNavigation = (e, path, hash) => {
    e.preventDefault();
    navigation(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  return (
    <div className="best-header__container pad">
      <div className="best-header__link-container">
        <Link to="/" className="best-header__link">
          Pagina de pornire
        </Link>

        <Link to="/about-us" className="best-header__link">
          Despre noi
        </Link>
        <Link to="/best-hotels" className="best-header__link">
          Cele mai bune hoteluri
        </Link>
        <Link to="/best-offers" className="best-header__link">
          Cele mai bune oferte
        </Link>
        <a
          href="/#contacts"
          className="best-header__link"
          onClick={(e) => {
            handleNavigation(e, "/", "contacts");
          }}
        >
          Kontakty
        </a>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="best-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="best-header__link" onClick={handleClick}>
              Pagina de pornire
            </Link>
            <Link
              to="/about-us"
              className="best-header__link"
              onClick={handleClick}
            >
              Despre noi
            </Link>
            <Link
              to="/best-hotels"
              className="best-header__link"
              onClick={handleClick}
            >
              Cele mai bune hoteluri
            </Link>
            <Link
              to="/best-offers"
              className="best-header__link"
              onClick={handleClick}
            >
              Cele mai bune oferte
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
