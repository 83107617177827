import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="best-privacy">
        <h1 className="privacy__header">
          Politica de confidențialitate pentru best-rooffers.com
        </h1>
        <p className="t-16">
          La best-rooffers.com, accesibil prin best-rooffers.com, una dintre
          principalele noastre priorități este confidențialitatea vizitatorilor
          noștri. Acest document privind politica de confidențialitate conține
          tipurile de informații colectate și înregistrate de best-rooffers.com
          și modul în care le utilizăm.
        </p>
        <p className="t-16">
          Dacă aveți întrebări suplimentare sau aveți nevoie de mai multe
          informații despre politica noastră de confidențialitate, nu ezitați să
          ne contactați.
        </p>
        <h2 className="privacy__header">Fișiere de jurnal</h2>
        <p className="t-16">
          best-rooffers.com urmează o procedură standard de utilizare a
          fișierelor jurnal. Aceste fișiere înregistrează vizitatorii atunci
          când vizitează site-uri web. Toate companiile de găzduire fac acest
          lucru și face parte din analiza serviciilor de găzduire. Informațiile
          colectate de fișierele jurnal includ adresele IP (protocolul de
          internet), tipul de browser, furnizorul de servicii de internet (ISP),
          data și ora, paginile de referință/ieșire și, eventual, numărul de
          clicuri. Acestea nu sunt legate de informații care pot fi identificate
          personal. Scopul informațiilor este de a analiza tendințele, de a
          administra site-ul, de a urmări mișcările utilizatorilor pe site și de
          a colecta informații demografice. Politica noastră de
          confidențialitate a fost creată cu ajutorul generatorului de politici
          de confidențialitate.
        </p>
        <h2 className="privacy__header">Partenerii noștri publicitari</h2>
        <p className="t-16">
          Unii dintre agenții de publicitate de pe site-ul nostru pot folosi
          cookie-uri și semnalizatoare web. Partenerii noștri publicitari sunt
          enumerați mai jos. Fiecare dintre acești parteneri de publicitate are
          propria politică de confidențialitate pentru politicile lor privind
          datele utilizatorilor. Pentru un acces mai ușor, am făcut legătura cu
          politicile lor de confidențialitate de mai jos.
        </p>
        <h2 className="privacy__header">Politicile de confidențialitate</h2>
        <p className="t-16">
          Puteți consulta această listă pentru a găsi politica de
          confidențialitate pentru fiecare dintre partenerii de publicitate ai
          best-rooffers.com.
        </p>
        <p className="t-16">
          Serverele de anunțuri sau rețelele de anunțuri terțe folosesc
          tehnologii precum cookie-uri, JavaScript sau semnalizatoare web care
          sunt utilizate în reclamele și linkurile respective care apar pe
          best-rooffers.com și sunt trimise direct către browserul
          utilizatorului. Aceștia primesc automat adresa IP atunci când se
          întâmplă acest lucru. Aceste tehnologii sunt utilizate pentru a măsura
          eficiența campaniilor lor publicitare și/sau pentru a personaliza
          conținutul publicitar pe care îl vedeți pe site-urile web pe care le
          vizitați.
        </p>
        <p className="t-16">
          Rețineți că best-rooffers.com nu are acces sau control asupra acestor
          cookie-uri utilizate de agenții de publicitate terți.
        </p>
        <h2 className="privacy__header">
          Politici de confidențialitate ale terților
        </h2>
        <p className="t-16">
          Politica de confidențialitate a best-rooffers.com nu se aplică altor
          agenți de publicitate sau site-uri web. Prin urmare, vă sfătuim să
          consultați politicile de confidențialitate ale serverelor de anunțuri
          terțe pentru informații mai detaliate. Acestea pot include practicile
          și instrucțiunile lor despre cum să renunțați la anumite opțiuni.
        </p>
        <p className="t-16">
          Puteți dezactiva cookie-urile prin opțiunile browserului
          dumneavoastră. Pentru informații mai detaliate despre gestionarea
          cookie-urilor cu anumite browsere web, consultați site-urile web ale
          browserelor respective. Ce sunt cookie-urile?
        </p>
        <h2 className="privacy__header">Informații pentru copii</h2>
        <p className="t-16">
          O altă parte a priorității noastre este protecția copiilor atunci când
          utilizează internetul. Încurajăm părinții și tutorii să observe, să
          participe și/sau să monitorizeze și să ghideze activitățile lor
          online.
        </p>
        <p className="t-16">
          best-rooffers.com nu colectează cu bună știință informații personale
          identificabile de la copii sub 13 ani. Dacă credeți că copilul
          dumneavoastră a furnizat astfel de informații pe site-ul nostru, vă
          încurajăm să ne contactați imediat și vom face tot posibilul pentru a
          elimina prompt aceste informații din înregistrările noastre.
        </p>
        <h2 className="privacy__header">
          Politica de confidențialitate doar online
        </h2>
        <p className="t-16">
          Această politică de confidențialitate se aplică doar activităților
          noastre online și este valabilă pentru vizitatorii site-ului nostru în
          ceea ce privește informațiile pe care le-au împărtășit și/sau colectat
          în best-rooffers.com. Această politică nu se aplică niciunei
          informații colectate offline sau prin alte canale decât acest site
          web.
        </p>
        <h2 className="privacy__header">Consimțământ</h2>
        <p className="t-16">
          Prin utilizarea site-ului nostru, sunteți de acord cu politica noastră
          de confidențialitate și sunteți de acord cu termenii și condițiile
          acesteia.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
