import React from "react";
import "./Main.css";
import About from "../About/About";
import Hotels from "../Hotels/Hotels";
import Deals from "../Deals/Deals";

// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="best-main">
      {/* <MainPopup/> */}
      <section className="best-main__cover">
        <h1 className="best-main__header text-center">
          Recenzii ale hotelurilor-cazinouri din România unde vă puteți relaxa
          și distra
        </h1>
        <h2 className="text-center best-main__text">
          Site-ul nostru vă ajută să găsiți rapid un hotel care satisface
          nevoile dumneavoastră.
        </h2>
      </section>
      <About />
      <Hotels />
      <Deals />
    </main>
  );
};

export default MainCover;
