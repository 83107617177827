import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";
import inst from "../../images/instagram.svg";
import telegram from "../../images/telegram.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="best-footer mg-top">
      <p className="best-footer__disclaimer t-16 text-center">
        Disclaimer
        <br></br>Toate informațiile de pe site sunt doar în scop informativ.
        Pentru informații detaliate și actualizate, vă recomandăm să contactați
        hotelul prin orice mijloace convenabile.
      </p>
      <div className="best-footer__text-container">
        <div className="best-footer__flex-container">
          <p className="t-16 text-center">
            Dacă doriți să ne contactați sau să lăsați feedback, nu ezitați! Ne
            puteți contacta prin e-mail. Acesta este e-mailul nostru:
          </p>
          <Link
            to="mailto:best-rooffers@gmail.com"
            className="best-footer__link"
          >
            best-rooffers@gmail.com
          </Link>
        </div>
        <Link to="/privacy" className="t-16 best-footer__policy pc">
        Politica de confidențialitate
        </Link>
        <div className="best-footer__flex-container">
          <p className="t-16 text-center">
            „best-rooffers.com” - Utilizează module cookie pentru a oferi cea
            mai bună experiență posibilă. Dacă rămâneți pe site, sunteți de
            acord cu utilizarea de către noi a modulelor cookie.
          </p>
          <Link
            to="/cookies"
            className="best-footer__link"
          >
            Mai multe informații
          </Link>
        </div>
        <Link to="/privacy" className="t-16 best-footer__policy mob">
        Politica de confidențialitate
        </Link>
      </div>
      <div className="best-footer__bottom-container">
        <img
          src={age}
          alt="age limit 18+"
          title="age limit 18+"
          width="54"
          height="54"
        />
        <div className="best-footer__links-container">
          <a href="#">
            <img src={inst} alt="instagram icon" title="instagram icon" />
          </a>
          <a href="#">
            <img src={telegram} alt="telegram icon" title="telegram icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
